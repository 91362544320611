.carousel-item {
    height: 35em;
}

.carousel-item img {
    margin: auto;
    width: 100%;
}

.texto-carrousel {
    font-size: 2.3rem;
    color: #000000;
    margin-bottom: 3rem;
    text-shadow: 1px 1px 2px rgb(255, 255, 255), 0 0 1em rgb(255, 255, 255), 0 0 0.2em rgb(255, 255, 255);
}
.formulario {
    background-color: #dad8ee;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imagen-brainy {
    width: 40%;
    height: auto;
    margin: 3rem 0 3rem;
}

h4 {
    margin: 10px;
    text-align: center;
}
.footer-superior {
    display: flex;
    margin: 10px;
    padding-top: 2rem;
}

.logo-footer {
    width: 15%;
    height: auto;
    min-width: 200px;
    margin: 0 30px;
}

.mapa-footer {
    width: 50%;
    height: 13rem;
    margin: auto;
    border-radius: 10px;
}

.contacto-footer {
    margin: auto;
    width: 20%;
}

.footer-inferior {
    margin-top: 30px;
    display: flex;
    align-items: center;
    background-color: #8a7584;
}

.texto-pie {
    margin: auto;
    font-size: 1.1rem;
    color: aliceblue;

}
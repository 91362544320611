.service-container {
    width: 100%;
    margin: 2rem auto;
    padding: 1rem;
    border-bottom: 1px solid #777;
}

.titulo-servicio {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(182, 181, 181);
}

.contenedor-servicio {
    margin: auto;
    padding: 1rem;
    width: 100%;
}

.div-superior {
    display: flex;
    justify-content: space-between;
}

.texto-superior {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.div-superior img {
    width: 35%;
    border-radius: 5px;
    box-shadow: 2px 2px 10px #a7a7a7;
}

.div-inferior {
    padding: 1rem;
}

.contenedor-cards-servicios {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.card-servicio-pagina {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    width: 22%;
    border-radius: 5%;
    overflow-y: auto;
    box-shadow: 2px 2px 10px #a7a7a7;
}

.titulo-card {
    background-color: rgb(246, 247, 248);
    padding: 1rem;
    border-bottom: 1px solid rgb(182, 181, 181);
    text-align: center;
}

.texto-card {
    padding: 1rem;
    font-weight: lighter;
    color: #616161;
}

.subtitulo {
    margin: 20px 0 5px 0;
    padding-bottom: 10px;
    color: black;
    font-size: 2rem;
    font-family: 'Signika Negative', sans-serif;
    font-weight: 500;
}

p {
    font-size: 1.2rem;
}
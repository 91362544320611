.links {
    text-decoration: none;
    font-size: 1.2rem;
    background-image: linear-gradient(#e73439, #e73439);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .7s;
    font-weight: 600;
    color: black;
}

.links-blancos {
    background-image: linear-gradient(#ffffff, #ffffff);
}

.links-linkedin {
    background-image: linear-gradient(to right, #2020d4, #0ae7ff);
}

.links-instagram {
    background-image: linear-gradient(to right, #d5358d, #e4df3c);
}

.links:hover,
.links:focus {
    background-size: 100% 2px;
    text-shadow: 1px 1px 2px #ffeeb5, 0 0 1em #ffeeb5, 0 0 0.2em #ffeeb5;

}
.redes {
    margin: 10px;
    width: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.imagen-boton {
    width: 40px;
    height: auto;
    margin-bottom: 3px;
}

.redes-colaboradores {
    margin: 10px;
    width: 35%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.imagen-redes {
    min-width: 50px;
    height: 53px;
    margin-bottom: 3px;
}
html,
body {
  max-width: 100vw;
  font-family: 'MyriadPro', sans-serif;
  font-size: 1em;
}

.titulos {
  margin: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(182, 181, 181);
  color: black;
  font-size: 2rem;
  font-family: 'MyriadPro', sans-serif;
  font-weight: 500;
}

.right {
  text-align: end;
}

.div-btn-matricula {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem auto;
}

.div-principal-colaboradores {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.texto-colaboradores {
  margin: 2rem 0;
  width: 90%;
  font-size: 1.1rem;
}
.contenedor-diferenciadores {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto;
}

.content {
    display: flex;
    align-items: center;
    width: 45%;
    margin: auto;
}

.izq {
    order: -1;
    margin-right: 10px;
}

.der {
    margin-left: 10px;
}

.text {
    flex: 1;
    padding: 20px;

}

p {
    font-weight: lighter;
    color: #616161;
}

.imagen-diferenciadora {
    width: 12em;
    height: 12em;
    margin: 10px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 2px 2px 10px #a7a7a7;
}

.titulo-diferenciador {
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(182, 181, 181);
    font-weight: bolder;
    font-size: 1.4rem;
}
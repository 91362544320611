.card-servicio {
    border: 1px solid rgb(182, 181, 181);
    margin: 20px;
    border-radius: 10px;
    display: flex;
    width: 40%;
    height: auto;
    min-height: 15rem;
    box-shadow: 2px 2px 10px #a7a7a7;
}

.imagen-servicio {
    max-width: 40%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
}

.textos-servicio {
    width: 60%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow-y: auto;
    font-weight: lighter;
    color: #616161;
}

.fucsia {
    background: linear-gradient(#e42185 0%, #ffc5e3 10%, #ffffff 100%);
    ;
}

.azul {
    background: linear-gradient(#35a7df 0%, #bddbf5 10%, #ffffff 100%);
}

.verde {
    background: linear-gradient(#56af31 0%, #d3e5bb 10%, #ffffff 100%);
}

.morada {
    background: linear-gradient(#a1398d 0%, #e2c1d9 10%, #ffffff 100%);
}

.contenedor-servicios {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.boton-servicio {
    background: transparent;
    border: none;
    text-shadow: 1px 1px 2px rgb(255, 255, 255), 0 0 1em rgb(255, 255, 255), 0 0 0.2em rgb(255, 255, 255);
    font-size: 1.4rem;
    text-decoration: underline;
    text-transform: capitalize;
}
.header-superior {
    width: 100%;
    border-bottom: 1px solid rgb(182, 181, 181);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.logo-horizontal {
    width: auto;
    height: 100px;
    margin: 1%;
}

.contacto-header {
    display: flex;
    width: 40%;
    justify-content: center
}
.colaboradores-div {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: center;
    align-self: center;
}

.card {
    min-width: 13rem;
    width: 20%;
    margin: 10px;
    box-shadow: 2px 2px 10px #a7a7a7;
}

.imagen-superior {
    width: 100%;
    height: 40%;
    object-fit: cover;
}

.card-titulo {
    font-weight: bolder;
    font-size: 1.4rem;
}

.card-texto {
    font-weight: lighter;
    color: #616161;
}

.contenedor-cohete {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.boton-card {
    background: transparent;
    border: none;
}

.cohete {
    width: 60px;
    height: auto;
    border-radius: 50% 0% 50% 0%;
    transition: 500ms ease;
    box-shadow: 0px 15px 10px #929090;
}

.cohete:hover {
    box-shadow: 0px 15px 10px rgb(242, 141, 60, 1);
    transform: scale(1.1);
}
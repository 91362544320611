.div-btn-matricula {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.btn-principal {
    text-decoration: none;
    padding: 7px 20px 3px 20px;
    font-size: 1.6rem;
    font-weight: bold;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    outline: none;
    border-radius: 15px;
    z-index: 0;
    background: transparent;
    overflow: hidden;
    border: 2px solid #a1398d;
    color: #a1398d;
}

.btn-principal:hover {
    transform: scale(1.02);
    box-shadow: 2px 2px 10px #464646;
    color: #fff;
}

.btn-principal:hover:after {
    width: 100%;
}

.btn-principal:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition: all 0.3s ease;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background: #a1398d;
}
@media (max-width: 470px) {

    /*HEADER*/

    #contacto-header {
        display: none;
    }

    .header-superior {
        background: linear-gradient(#8a7584, rgb(255, 255, 255));
        flex-direction: column;
        padding: 30px;
    }

    .redes {
        min-width: 50%;
    }

    /*CARROUSEL*/

    .carousel-inner img {
        width: 100%;
        height: auto;
    }

    .carousel-inner {
        height: 300px;
    }

    /*DIFERENCIADORES*/

    .content {
        min-width: 100%;
        flex-direction: column;
    }

    /*COLABORADORES*/

    .colaboradores-div {
        flex-direction: column;
        min-width: 100%;
    }

    div .card {
        min-width: 90%;
        height: auto;
        margin: 10% auto;
    }

    /*SERVICIOS*/

    div.card-servicio {
        width: 100%;
    }

    /*FOOTER*/

    div .footer-superior {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin: auto 0;
    }

    .mapa-footer {
        min-width: 100%;
        padding: 10px 5px;
    }

    .texto-pie {
        width: 90%;
        text-align: center;
        padding-top: 10px;
    }

    div .contacto-footer {
        min-width: 100%;
        padding: 10px;
    }

    div .footer-inferior {
        display: flex;
        margin-top: 0px;
        flex-direction: column;
    }

    /*WHY-US*/

    .card-why {
        width: 90%;
        margin: auto;
    }

    /*PAGINA DOCENTES*/

    div .docente {
        flex-direction: column;
    }

    div .docente-info,
    div .div-docente-img {
        width: 100%;
    }

    div .docente-info {
        margin-left: 0;
        margin-top: 1rem;
    }

    div .div-docente-img {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    div .redes-colaboradores {
        width: 50%;
    }

    /*PAGINA-SERVICIOS*/

    .div-superior {
        flex-direction: column;
    }

    div .texto-superior {
        width: 100%;
        padding: 0;
    }

    div .contenedor-servicio {
        padding: 0;
    }

    div .div-superior img {
        width: 100%;
        margin-top: 1rem;
    }

    div .card-servicio-pagina {
        width: 100%;
    }
}

@media (min-width:470px) and (max-width: 990px) {

    /*HEADER*/

    .redes {
        min-width: 35%;
    }

    #contacto-header {
        flex-direction: column;
    }

    div .header-superior {
        background: linear-gradient(#8a7584, rgb(255, 255, 255));
        padding: 20px;
    }

    /*CARROUSEL*/

    .carousel-inner img {
        width: 100%;
        height: auto;
    }

    /*DIFERENCIADORES*/

    .content {
        min-width: 50%;
        flex-direction: column;
    }

    /*COLABORADORES*/

    .colaboradores-div {
        min-width: 100%;
    }

    div .card {
        min-width: 45%;
        height: auto;
        margin: 10% auto;
    }

    /*SERVICIOS*/

    div.card-servicio {
        width: 40%;
    }

    /*FOOTER*/

    div .footer-superior {
        flex-direction: column;
        align-items: center;
        margin: auto 0;
    }

    .mapa-footer {
        min-width: 100%;
        padding: 10px 5px;
    }

    .texto-pie {
        width: 90%;
        text-align: center;
        padding: 10px;
    }

    div .footer-inferior {
        margin-top: 0px;
    }

    div .contacto-footer {
        min-width: 100%;
        padding: 10px;
    }

    /*WHY-US*/

    .card-why {
        width: 90%;
        margin: auto;
    }

    /*PAGINA DOCENTES*/

    div .docente {
        flex-direction: column;
    }

    div .docente-info {
        width: 100%;
    }

    div .div-docente-img {
        width: 80%;
        align-self: center;
    }

    div .div-docente-img {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    /*PAGINA-SERVICIOS*/

    .div-superior {
        flex-direction: column;
    }

    div .texto-superior {
        width: 100%;
        padding: 0;
    }

    div .div-superior img {
        width: 50%;
        margin: auto;
        margin-top: 1rem;
    }

    div .card-servicio-pagina {
        width: 45%;
    }


}

@media (min-width: 990px) {
    .navbar-nav {
        min-width: 80%;
        justify-content: space-around;
    }

    .contenedor-redes-menu {
        order: -1;
        justify-content: flex-start;
    }

    .carousel-inner img {
        margin-top: -40vh;
    }
}
.contacto-principal {
    display: flex;
    flex-direction: row;
    font-size: 1.1rem;
}

.div-imagen {
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imagen {
    width: 35px;
    height: auto;
}

.contacto-horizontal {
    padding: 10px;
}

.texto-principal {
    font-weight: bolder;
    font-size: 1.1rem;
    margin: 0;
    color: black;
}

.texto-secundario {
    line-height: 20px;
    color: rgb(122, 121, 121);
    transition: 500ms ease;
}

.texto-secundario:hover {
    color: #35a7df;
    text-shadow: 1px 1px 2px #bcdbf5, 0 0 1em #bcdbf5, 0 0 0.2em #dad8ee;
    transform: scale(1.05);
}
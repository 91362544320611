.docente {
    margin: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 5rem;
    border-bottom: 1px solid #777;
}

.div-docente-img {
    width: 30%;
    height: auto;
}

.imagen-docente {
    width: 100%;
    border-radius: 5%;
    margin: 2rem 0;
    box-shadow: 2px 2px 10px #a7a7a7;
}

.docente-info {
    width: 50%;
    line-height: 2rem;
    font-size: 1.2rem;
}

.nombre-docente {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(182, 181, 181);
}

.subtitulo {
    margin: 20px 0 5px 0;
    padding-bottom: 10px;
    color: black;
    font-size: 2rem;
    font-weight: 700;
    font-family: 'MyriadPro', sans-serif;
}



.hobbies {
    display: flex;
    flex-wrap: wrap;
}

.hobbies span {
    background-color: #ffffff;
    box-shadow: 2px 2px 10px #a7a7a7;
    color: #000000;
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

p {
    margin-bottom: 0;
}

li {
    color: #616161;
}
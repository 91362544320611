.contenedor-why {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
}

.card-why {
    height: auto;
    width: 45%;
    border: 1px solid rgb(184, 184, 184);
}

.contenedor-notas,
.contenedor-politicas {
    width: 90%;
    margin: 30px auto;
}

.sin-fondo {
    background: transparent;
}

.boton-nota {
    margin: 20px auto;
}

.textarea {
    background-color: rgb(235, 239, 240);
}